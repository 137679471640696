<template>
  <v-container v-if="$can([titlePerms + '_informe'])" fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="informe in informes"
        :key="informe.id"
      >
        <v-card
          class="pa-5"
          outlined
          @click="navegarAInforme(informe.ruta)"
          :disabled="informe.isDisabled"
        >
          <v-card-text class="d-flex justify-center">
            <v-card-title>{{ informe.titulo }}</v-card-title>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="$router.push('gestion_stock_estampillas')"
          >Atrás</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SeleccionInformes",
  data: () => ({
    titlePerms: "gestion_stock_estampillas",
    informes: [],
    /*informes: [
      {
        id: 1,
        isDisabled: false,
        titulo: "Almacén de Venta",
        ruta: "/stock-estampillas/informes/almacen-venta",
      },
      { id: 2, isDisabled: true, titulo: "Almacén de Tránsito", ruta: "/" },
      {
        id: 3,
        isDisabled: true,
        titulo: "Almacén de Control",
        ruta: "/ruta-informe-3",
      },
    ],
    */
  }),
  methods: {
    navegarAInforme(ruta) {
      this.$router.push(ruta);
    },
    setItems() {
      //evaluo los accesos en base a los permisos
      if (this.$can(["almacen_venta_list"]) === true) {
        this.informes.push({
          id: 1,
          isDisabled: false,
          titulo: "Almacén de Venta",
          ruta: "/stock-estampillas/informes/almacen-venta",
        });
      }
      if (this.$can(["almacen_transito_list"]) === true) {
        this.informes.push({
          id: 2,
          isDisabled: true,
          titulo: "Almacén de Tránsito",
          ruta: "/",
        });
      }
      if (this.$can(["almacen_control_list"]) === true) {
        this.informes.push({
          id: 3,
          isDisabled: true,
          titulo: "Almacén de Control",
          ruta: "/ruta-informe-3",
        });
      }
    },
  },
  created() {
    this.setItems();
  },
};
</script>
